.containerSidebar {
    background-color: #b1d9e3 !important;
    color: #000;
    width: 20%;
    margin-top: -20px;
    position: relative;
  }
  
  .containerList {
    list-style: none;
  }
  
  .title {
    padding: 10px;
    display: flex;
    align-items: center;
    margin: 10px;
  }
  
  .title-logout {
    padding: 10px;
    display: flex;
    align-items: center;
    margin: 10px 10px 0px 10px;
    position: absolute;
    bottom: 20px;
    width: 210px;
  }
  
  .title-active {
    background-color: #F81 !important;
    padding: 10px;
    display: flex;
    align-items: center;
    margin: 10px;
    border-radius: 8px;
  }
  
  
  .title:hover {
    background-color: #6f898f;
    color: #FFF;
    cursor: pointer;
    border-radius: 8px;
  }
  
  .title-logout:hover {
    background-color: #6f898f;
    color: #FFF;
    cursor: pointer;
    border-radius: 8px;
  }