.container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    overflow: hidden;
}

.wrapp-form {
    padding: 20px 100px;
    width: 100%;
    height: 550px;
    border: 5px solid white;
    box-shadow: 0 5px 10px rgb(255, 255, 255);
    color: #000;
}